import { Component, Input, ViewChildren, QueryList, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl, ValidationErrors } from '@angular/forms';
import { ObsForm } from 'src/app/shared/models/obs-form.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ResponseTypeEnum } from 'src/app/shared/enums/response-type.enum';
import { FsObservation } from 'src/app/shared/models/fs-observation.model';

@Component({
  selector: 'app-obs-domains',
  templateUrl: './obs-domains.component.html',
  styleUrls: ['./obs-domains.component.scss']
})

export class ObsDomainsComponent implements OnInit {
  @Input() obsForm: ObsForm;
  @Input() fsObservation: FsObservation;
  @Input() obsAssessmentForm: FormGroup;
  @Input() completed: boolean;
  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;
  @Output() responseChanged:EventEmitter<{ questionId: number, response: any }> = new EventEmitter<{ questionId: number, response: any }>();

  obsDomainsForm: FormGroup;
  allPanelsExpanded = false;
  ResponseTypeEnum = ResponseTypeEnum;
  finishing: boolean;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.obsDomainsForm = this.fb.group({});
    this.createFormControls();
    this.mapResponsesToObsForm();
    this.obsAssessmentForm.addControl('obsDomains', this.obsDomainsForm);
    
    if (this.completed) {
      this.obsDomainsForm.disable();
    }
  }

  ngOnDestroy() {
    // Remove the form control from the parent form so that the child form controls are 
    // successfully reinitialized when the form is reloaded after being hidden.
    this.obsAssessmentForm.removeControl('obsDomains');
  }

  mapResponsesToObsForm() {
    this.obsForm.obsDomains.forEach(domain => {
      domain.obsCategories.forEach(category => {
        const responses = this.fsObservation.obsQuestionResponses.filter(res => res.obsCategoryId === category.id);
        if (responses.length === 0) {
          return;
        }
        category.obsQuestions.forEach(question => {
          let response = responses.find(res => res.id === question.id);
          if (response) {
            question.response = response.responseTypeId === ResponseTypeEnum.Rating ? Number(response.response) : response.response;
            this.obsDomainsForm.get(question.id.toString())?.setValue(question.response);
          }
        });
      });
    });
  }

  createFormControls() {
    this.obsForm.obsDomains.forEach(domain => {
      domain.obsCategories.forEach(category => {
        category.obsQuestions.forEach(question => {
          const control = new FormControl(question.response || (question.responseTypeId === ResponseTypeEnum.Rating ? null : ''));
          this.obsDomainsForm.addControl(question.id.toString(), control);

          // Subscribe to value changes
          control.valueChanges.subscribe(value => {
            question.response = value;
          });
        });
      });
    });
  }

  requiredValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return { required: true };
    }
    return null;
  }

  updateValidators() {
    this.obsForm.obsDomains.forEach(domain => {
      domain.obsCategories.forEach(category => {
        category.obsQuestions.forEach(question => {
          const control = this.obsDomainsForm.get(question.id.toString());
          if (control) {
            control.setValidators(this.requiredOnFinishValidator.bind(this));
            control.updateValueAndValidity();
          }
        });
      });
    });
  }

  requiredOnFinishValidator(control: AbstractControl): ValidationErrors | null {
    if (this.finishing && (control.value === null || !this.isNonEmptyString(control.value))) {
      return { required: true };
    }
    return null;
  }

  isNonEmptyString(value: any): boolean {
    if (typeof value != 'string'){
      return true;
    }
    else {
      return typeof value === 'string' && value.trim().length > 0;
    }
  }

  validateForm(isFinishing: boolean = false): string[] {
    let errors = [];
    this.finishing = isFinishing;
    this.markFormGroupsAsTouched(this.obsDomainsForm);
    this.updateValidators();

    if (this.finishing && this.obsDomainsForm.invalid) {

      const errorMessages = this.getErrorMessages();

      errors = errors.concat(errorMessages);
    } else {
      this.finishing = false;
    }
    
    return errors;
  }

  getErrorMessages(): string[] {
    let errors: string[] = [];
    this.obsForm.obsDomains.forEach(domain => {
      domain.obsCategories.forEach(category => {
        category.obsQuestions.forEach(question => {
          const control = this.obsDomainsForm.get(question.id.toString());
          if (control && control.errors) {
            if (control.errors.required) {
              if (question.responseTypeId === ResponseTypeEnum.Rating) {
                errors.push(`${category.description} proficiency rating is required.`);
              } else {
                errors.push(`${category.description} ${question.description.toLowerCase()} is required.`);
              }
            }
          }
        });
      });
    });
    return errors;
  }

  markFormGroupsAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
      if (control instanceof FormGroup) {
        this.markFormGroupsAsTouched(control);
      }
    });
    formGroup.markAsTouched();
  }

  toggleAllPanels() {
    this.allPanelsExpanded = !this.allPanelsExpanded;
    this.panels.forEach(panel => this.allPanelsExpanded ? panel.open() : panel.close());
  }

  updateObsFormWithLatestData() {
    this.obsForm.obsDomains.forEach(domain => {
      domain.obsCategories.forEach(category => {
        category.obsQuestions.forEach(question => {
          const control = this.obsDomainsForm.get(question.id.toString());
          if (control) {
            question.response = control.value;
          }
        });
      });
    });
  }
}
