import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';


import { InternSearchComponent } from './intern/intern-search/intern-search.component';
import { ModelPopupComponent } from './shared/model-popup/model-popup.component';
import { InternDetailsComponent } from './intern/intern-details/intern-details.component';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { ObservationDetailsComponent } from './intern/observation/observation-details/observation-details.component';
import { ObservationPlanningComponent } from './intern/observation/observation-planning/observation-planning.component';
import { ObservationInstructionComponent } from './intern/observation/observation-instruction/observation-instruction.component';
import { ObservationLearningComponent } from './intern/observation/observation-learning/observation-learning.component';
import { ObservationProfessionalComponent } from './intern/observation/observation-professional/observation-professional.component';
import { NontexasContactComponent } from './intern/non-texas/nontexas-contact/nontexas-contact.component';
import { EmailComponent } from './shared/email/email.component';
import { AuthComponent } from './auth/auth.component';
import { SelectSupervisorComponent } from './auth/select-supervisor/select-supervisor.component';
import { GlobalService } from './services/global.service';
import { ObservationFormErrorSheetComponent } from './intern/observation/observation-form-error-sheet/observation-form-error-sheet.component';
import { LockoutNotificationComponent } from './auth/lockout-notification/lockout-notification.component';

import { InternWhatsNextComponent } from './intern/intern-whats-next/intern-whats-next.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AuthService } from './services/auth.service';
import { ObservationAttachmentComponent } from './intern/observation/observation-attachment/observation-attachment.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ConfigService } from './services/config.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { MentorObservationsComponent } from './intern/mentor-observations/mentor-observations.component';
import { MsalRedirectComponent } from './msal-redirect/msal-redirect.component';
import { InformalObservationComponent } from './intern/observation/informal-observation/informal-observation.component';
import { ConferenceComponent } from './intern/conference/conference.component';
import { NewContactComponent } from './shared/new-contact/new-contact.component';
import { ObsAssessmentComponent } from './intern/obs-assessment/obs-assessment.component';
import { ObsDetailsComponent } from './intern/obs-assessment/obs-details/obs-details.component';
import { ObsDomainsComponent } from './intern/obs-assessment/obs-domains/obs-domains.component';
import { ObsSummaryComponent } from './intern/obs-assessment/obs-summary/obs-summary.component';
import { ObsConferenceComponent } from './intern/obs-assessment/obs-conference/obs-conference.component';
import { FilterByIdPipe } from './shared/pipe-filters/filter-by-id.pipe';
import { ProgressBarComponent } from './intern/intern-whats-next/progress-bar/progress-bar.component';
import { SpinnerOverlayComponent } from './shared/spinner-overlay/spinner-overlay.component';

export function loadConfiguration(configService: ConfigService) {
  return () => configService.loadConfigs();
}

export function msalInstanceFactory(configService: ConfigService): IPublicClientApplication  {
  return configService.getMsalConfiguration();
}
export function loadMsalGuardConfiguration(configService: ConfigService): MsalGuardConfiguration {
  return configService.getMsalGuardConfiguration();
}
export function loadMsalInterceptorConfiguration(configService: ConfigService): MsalInterceptorConfiguration {
  return configService.getMsalInterceptorConfiguration();
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        InternSearchComponent,
        ModelPopupComponent,
        InternDetailsComponent,
        ObservationDetailsComponent,
        ObservationPlanningComponent,
        ObservationInstructionComponent,
        ObservationLearningComponent,
        ObservationProfessionalComponent,
        NontexasContactComponent,
        EmailComponent,
        AuthComponent,
        SelectSupervisorComponent,
        ObservationFormErrorSheetComponent,
        LockoutNotificationComponent,
        InternWhatsNextComponent,
        ObservationAttachmentComponent,
        ConfirmDialogComponent,
        MentorObservationsComponent,
        MsalRedirectComponent,
        InformalObservationComponent,
        ConferenceComponent,
        NewContactComponent,
        ObsAssessmentComponent,
        ObsDetailsComponent,
        ObsDomainsComponent,
        ObsSummaryComponent,
        ObsConferenceComponent,
        FilterByIdPipe,
        ProgressBarComponent,
        SpinnerOverlayComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        DataTablesModule,
        MaterialModule,
        MsalModule,
        BrowserAnimationsModule,
        AngularEditorModule,
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: loadConfiguration, deps: [ConfigService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: MSAL_INSTANCE, useFactory: msalInstanceFactory, deps: [ConfigService] },
        { provide: MSAL_GUARD_CONFIG, useFactory: loadMsalGuardConfiguration, deps: [ConfigService] },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: loadMsalInterceptorConfiguration, deps: [ConfigService] },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, deps: [AuthService], multi: true },
        DatePipe, { provide: LocationStrategy, useClass: HashLocationStrategy },
        GlobalService,
        ConfigService,
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
