import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges {
  @Input() progressText: string;
  @Input() progress: number;
  @Input() fullProgress: number;

  progressPercentage: number;

  ngOnInit() {
    this.updateProgress();
  }

  ngOnChanges() {
    this.updateProgress();
  }

  updateProgress() {
    this.progressText = this.progressText
      .replace('{{Progress}}', this.progress.toString())
      .replace('{{FullProgress}}', this.fullProgress.toString());
    this.progressPercentage = this.progress <= 0 ? 0 : (this.progress / this.fullProgress) * 100;
  }
}
