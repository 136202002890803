import { Component, Input, OnInit} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ObservationService } from 'src/app/services/observation.service';
import { AssessmentOption } from 'src/app/shared/models/assessmentOption';
import { FsObservation } from 'src/app/shared/models/fs-observation.model';

@Component({
  selector: 'app-obs-summary',
  templateUrl: './obs-summary.component.html',
  styleUrls: ['./obs-summary.component.scss']
})
export class ObsSummaryComponent implements OnInit {
  @Input() obsAssessmentForm: FormGroup;
  @Input() fsObservation: FsObservation;  
  @Input() completed: boolean;
  obsSummaryForm: FormGroup;
  finishing: boolean;
  overallAssessmentOptions: Array<AssessmentOption>;

  constructor(private observationService: ObservationService, private fb: FormBuilder) {}

  ngOnInit() {
    this.initializeFormGroup();
    this.getOverallAssessmentOptions();
  }

  private initializeFormGroup() {
    this.obsSummaryForm = this.fb.group({
      pendingRequirements: [this.fsObservation.pendingRequirements ?? '', this.requiredOnFinishValidator.bind(this)],
      strengths: [this.fsObservation.strengths ?? '', [Validators.maxLength(500), this.requiredOnFinishValidator.bind(this)]],
      areasOfGrowth: [this.fsObservation.areasOfGrowth ?? '', [Validators.maxLength(500), this.requiredOnFinishValidator.bind(this)]],
      actionableSteps: [this.fsObservation.actionableSteps ?? '', [Validators.maxLength(500), this.requiredOnFinishValidator.bind(this)]],
      otherNotes: [this.fsObservation.otherNotes ?? '', [Validators.maxLength(500), this.requiredOnFinishValidator.bind(this)]],
      overallAssessmentId: [this.fsObservation.overallAssessmentId ?? 0, this.overallAssessmentValidator.bind(this)]
    });
    this.obsAssessmentForm.addControl('obsSummary', this.obsSummaryForm);

    if (this.completed) {
      this.obsSummaryForm.disable();
    }
  }

  ngOnDestroy() {
    // Remove the form control from the parent form so that the child form controls are 
    // successfully reinitialized when the form is reloaded after being hidden.
    this.obsAssessmentForm.removeControl('obsSummary');
  }

  getOverallAssessmentOptions() {
    this.observationService.GetAssessmentTypeOptions().subscribe(data => {
      this.overallAssessmentOptions = data;
    });
  }

  validateForm(isFinishing: boolean = false): string[] {
    let errors = [];
    this.finishing = isFinishing;
    this.markFormGroupsAsTouched(this.obsSummaryForm);

    this.updateValidators();

    if (this.finishing && this.obsSummaryForm.invalid) {
      const errorMessages = {
        pendingRequirements: this.getErrorMessage('pendingRequirements'),
        strengths: this.getErrorMessage('strengths'),
        areasOfGrowth: this.getErrorMessage('areasOfGrowth'),
        actionableSteps: this.getErrorMessage('actionableSteps'),
        otherNotes: this.getErrorMessage('otherNotes'),
        overallAssessmentId: this.getErrorMessage('overallAssessmentId')
      };
  
      for (const controlName in errorMessages) {
        if (this.obsSummaryForm.get(controlName).errors) {
          errors.push(errorMessages[controlName]);
        }
      }
    } else {
      this.finishing = false;
    }

    return errors;
  }

  updateValidators() {
    this.obsSummaryForm.get('pendingRequirements').updateValueAndValidity();
    this.obsSummaryForm.get('strengths').updateValueAndValidity();
    this.obsSummaryForm.get('areasOfGrowth').updateValueAndValidity();
    this.obsSummaryForm.get('actionableSteps').updateValueAndValidity();
    this.obsSummaryForm.get('otherNotes').updateValueAndValidity();
    this.obsSummaryForm.get('overallAssessmentId').updateValueAndValidity();
  }

  markFormGroupsAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
      if (control instanceof FormGroup) {
        this.markFormGroupsAsTouched(control);
      }
    });
    formGroup.markAsTouched();
  }

  requiredOnFinishValidator(control: AbstractControl): ValidationErrors | null {
    if (this.finishing && !control.value) {
      return { required: true };
    }
    return null;
  }

  overallAssessmentValidator(control: AbstractControl): ValidationErrors | null {
    if (this.finishing && control.value === 0) {
      return { min: true };
    }
    return null;
  }

  getErrorMessage(controlName: string): string {
    const control = this.obsSummaryForm.get(controlName);
    if (control.errors) {
      switch (controlName) {
        case 'pendingRequirements':
          if (control.errors.required) {
            return 'Teachers of Tomorrow Pending Requirements is required';
          }
          break;
        case 'strengths':
          if (control.errors.required) {
            return 'Strengths is required';
          }
          break;
        case 'areasOfGrowth':
          if (control.errors.required) {
            return 'Areas of Growth is required';
          }
          break;
        case 'actionableSteps':
          if (control.errors.required) {
            return 'Actionable Steps is required';
          }
          break;
        case 'otherNotes':
          if (control.errors.required) {
            return 'Other Notes is required';
          }
          break;
        case 'overallAssessmentId':
          if (control.errors.required) {
            return 'An Overall Assessment is required';
          }
          if (control.errors.min) {
            return 'Overall Assessment is required';
          }
          break;
      }
    }
    return '';
  }
}
