import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "./config.service";
import { ProgramChecklistStateGroup } from "../shared/models/program-checklist-state-group.model";

@Injectable({
    providedIn: 'root'
})

export class ProgramChecklistService {

    public programCheckListStateGroups: ProgramChecklistStateGroup[];

    constructor(private httpClient: HttpClient,
        private configService: ConfigService
        
    ) { }

    public getProgramChecklist(profileId: number) {
        if (this.programCheckListStateGroups && this.programCheckListStateGroups.length > 0){
            var copy = Array.from(this.programCheckListStateGroups);
            this.programCheckListStateGroups = [];
            var obs = new Observable<ProgramChecklistStateGroup[]>( (observer) => {
                observer.next(copy)
            });
            return obs
        }
        return this.httpClient.get<ProgramChecklistStateGroup[]>(`${this.configService.apiUrl}/v1.0/profile/${profileId}/program-checklist`);
    }
}
