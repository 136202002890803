// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  padding-bottom: 15px;
}

.label {
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .row {
    display: flex;
    gap: 11%;
  }
  .column {
    display: flex;
    flex-direction: column;
    flex: 0 1 40%;
  }
}
@media (max-width: 768px) {
  .row {
    display: flex;
    gap: 11%;
    flex-wrap: wrap;
  }
  .column {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
  }
}
.fs-row {
  display: flex;
  flex: 0 1 100%;
}

.singature {
  display: flex;
  align-items: center;
}

.fs-label {
  font-size: 16px;
}

.color-grayblue {
  color: #324758;
}`, "",{"version":3,"sources":["webpack://./src/app/intern/obs-assessment/obs-conference/obs-conference.component.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;;AAEA;EACI;IACI,aAAA;IACA,QAAA;EACN;EAEE;IACI,aAAA;IACA,sBAAA;IACA,aAAA;EAAN;AACF;AAGA;EACI;IACI,aAAA;IACA,QAAA;IACA,eAAA;EADN;EAIE;IACI,aAAA;IACA,sBAAA;IACA,cAAA;EAFN;AACF;AAKA;EACI,aAAA;EACA,cAAA;AAHJ;;AAMA;EACI,aAAA;EACA,mBAAA;AAHJ;;AAMA;EACI,eAAA;AAHJ;;AAMA;EACI,cAAA;AAHJ","sourcesContent":[".header {\n    padding-bottom: 15px;\n}\n\n.label {\n    padding-bottom: 15px;\n}\n\n@media (min-width: 768px) {\n    .row {\n        display: flex;\n        gap: 11%;\n    }\n\n    .column {\n        display: flex;\n        flex-direction: column;\n        flex: 0 1 40%;\n    }\n}\n\n@media (max-width: 768px) {\n    .row {\n        display: flex;\n        gap: 11%;\n        flex-wrap: wrap;\n    }\n\n    .column {\n        display: flex;\n        flex-direction: column;\n        flex: 0 0 100%;\n    }\n}\n\n.fs-row {\n    display: flex;\n    flex: 0 1 100%;\n}\n\n.singature {\n    display: flex;\n    align-items: center;\n}\n\n.fs-label {\n    font-size: 16px;\n}\n\n.color-grayblue {\n    color: #324758;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
