// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .row {
    display: flex;
    gap: 15px;
    flex: 1;
  }
}
@media (max-width: 768px) {
  .row {
    display: flex;
    gap: 15px;
    flex: 1;
    flex-wrap: wrap;
  }
}
.column {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1 100%;
}

.location {
  padding-bottom: 22px;
}

.color-grayblue {
  color: #324758;
}`, "",{"version":3,"sources":["webpack://./src/app/intern/obs-assessment/obs-details/obs-details.component.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;;AAEA;EACI;IACI,aAAA;IACA,SAAA;IACA,OAAA;EACN;AACF;AAEA;EACI;IACI,aAAA;IACA,SAAA;IACA,OAAA;IACA,eAAA;EAAN;AACF;AAGA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;AADJ;;AAIA;EACI,oBAAA;AADJ;;AAIA;EACI,cAAA;AADJ","sourcesContent":[".header {\n    padding-bottom: 15px;\n}\n\n@media (min-width: 768px) {\n    .row {\n        display: flex;\n        gap: 15px;\n        flex: 1;\n    }\n}\n\n@media (max-width: 768px) {\n    .row {\n        display: flex;\n        gap: 15px;\n        flex: 1;\n        flex-wrap: wrap;\n    }\n}\n\n.column {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n    flex: 1 100%;\n}\n\n.location {\n    padding-bottom: 22px;    \n}\n\n.color-grayblue {\n    color: #324758;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
