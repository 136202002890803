import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InternService } from '../../services/intern.service';
import { Checklist } from '../../shared/models/checklist.model';
import { ProgramChecklistService } from 'src/app/services/program-checklist.service';
import { ProgramChecklistStateGroup } from 'src/app/shared/models/program-checklist-state-group.model';

@Component({
  selector: 'app-intern-whats-next',
  templateUrl: './intern-whats-next.component.html',
  styleUrls: ['./intern-whats-next.component.scss']
})
export class InternWhatsNextComponent implements OnInit {
  internId: string;
  profileId: number;
  internData: Checklist[];
  loading = true;
  licenseName: string | undefined;
  licenseNames: { [key: string]: string } = {
    'Arizona': 'Standard Professional Teaching Certificate',
    'Florida': 'Professional Certificate',
    'Indiana': "Practitioner's License",
    'Michigan': 'Standard Certificate',
    'Nevada': 'Standard License',
    'North Carolina': 'Professional Educators License',
    'South Carolina': 'Professional Teaching License',
    'Texas': 'Standard Certification'
  }
  pathwayToCertification: ProgramChecklistStateGroup[];

  constructor(public dialogRef: MatDialogRef<InternWhatsNextComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InternWhatsNextData,
    private internService: InternService,
    private programChecklistService: ProgramChecklistService) {
    this.internId = data.internId;
    this.profileId = data.profileId;
    this.licenseName = this.licenseNames[data.internState];
  }

  ngOnInit() {
    this.programChecklistService
      .getProgramChecklist(this.profileId)
      .subscribe(data => {
        if (data == null || data.length == 0) {
          this.loadOldWhatsNextData();
          return;
        }
        this.dialogRef.updateSize('500px', '500px');
        this.loading = false;
        this.pathwayToCertification = data;
      });
  }

  private loadOldWhatsNextData() {
    this.internService
      .getWhatsNextData(this.internId)
      .subscribe(data => {
        this.loading = false;
        this.internData = data;
      });
  }

  get rightNowData() {
    if (this.internData == null) {
      return null;
    }

    return this.internData.find(cl => cl.category == 0);
  }

  get haveAJobData() {
    if (this.internData == null) {
      return null;
    }

    return this.internData.find(cl => cl.category == 1);
  }

  get licenseData() {
    if (this.internData == null) {
      return null;
    }

    return this.internData.find(cl => cl.category == 2);
  }

  close() {
    this.dialogRef.close();
  }
}

export class InternWhatsNextData {
  internId: string;
  internState: string;
  profileId: number;
}
