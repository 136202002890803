import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { ObservationService } from 'src/app/services/observation.service';
import { MileageOptionsEnum } from 'src/app/shared/enums/mileage-options.enum';
import { VisitationFormatEnum } from 'src/app/shared/enums/visitation-format.enum';
import { campus } from 'src/app/shared/models/campus';
import { FsObservation } from 'src/app/shared/models/fs-observation.model';
import { MileageOption } from 'src/app/shared/models/mileageOption';
import { VisitationFormat } from 'src/app/shared/models/visitation-format';

@Component({
  selector: 'app-obs-details',
  templateUrl: './obs-details.component.html',
  styleUrls: ['./obs-details.component.scss']
})
export class ObsDetailsComponent implements OnInit { 
  @Input() obsAssessmentForm: FormGroup;
  @Input() fsObservation: FsObservation;
  @Input() isTexasObs: boolean;
  @Input() campus: campus;
  @Input() completed: boolean;
  @Output() requiredDetailsInvalid = new EventEmitter<boolean>();
  visitationFormatOptions: Array<VisitationFormat>;
  isFullTimeFieldSupervisor: boolean;
  mileageOptions: Array<MileageOption>;
  showMileageField: boolean = false;
  obsDetailsForm: FormGroup;
  minimumContactDuration: number;

  constructor(private observationService: ObservationService,
    private globalService: GlobalService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.initializeFormGroup();
    this.getFieldSupervisor();
    this.getVisitationFormatOptions();

    this.minimumContactDuration = this.isTexasObs ? 45 : 30;
    this.calculateDuration();

  }

  private initializeFormGroup() {
    this.obsDetailsForm = this.fb.group({
      obsDescription: [{ value: this.fsObservation.obsDescription, disabled: true }],
      subject: [this.fsObservation.subject, Validators.required],
      visitationFormat: [this.fsObservation.visitationFormat, Validators.required],
      mileageOption: [this.fsObservation.mileageOptionId, this.mileageOptionValidator.bind(this)],
      startTime: [this.fsObservation.startTime, [Validators.required, this.timeRangeValidator.bind(this)]],
      endTime: [this.fsObservation.endTime, [Validators.required, this.timeRangeValidator.bind(this)]],
      duration: [{ value: null }, this.durationValidator.bind(this)],
    });
    this.obsAssessmentForm.addControl('obsDetails', this.obsDetailsForm);

    if (this.completed) {
      this.obsDetailsForm.disable();
    }
  }

  ngOnDestroy() {
    // Remove the form control from the parent form so that the child form controls are 
    // successfully reinitialized when the form is reloaded after being hidden.
    this.obsAssessmentForm.removeControl('obsDetails');
  }

  getFieldSupervisor() {
    if (this.globalService.supervisor != undefined) {
      this.isFullTimeFieldSupervisor = this.globalService.supervisor.isFullTimeEmployee;
      if (this.isFullTimeFieldSupervisor) {
        this.getMileageOptions();
      }
    }
  }

  private getMileageOptions() {
    this.observationService.GetMileageOptions().subscribe(data => {
      this.mileageOptions = data.filter(mo => mo.id != MileageOptionsEnum.VirtualObservation);
      if (this.mileageOptions.length > 0) {
        this.showMileageField = this.isMileageFieldRequired();
      }
    })
  }
  
  private isMileageFieldRequired() {
    const isObservationInPerson =
      this.fsObservation.visitationFormat === VisitationFormatEnum.InPerson
      && this.isFullTimeFieldSupervisor;

    return isObservationInPerson;
  }

  getVisitationFormatOptions() {
    this.observationService.GetVisitationFormatOptions().subscribe(data => {
      this.visitationFormatOptions = data;
    });
  }

  onVisitationFormat_Changed(){
    const isInperson = this.obsDetailsForm.get('visitationFormat').value == VisitationFormatEnum.InPerson;

    if (isInperson && this.isFullTimeFieldSupervisor) {
      this.showMileageField = true;
    }
    else {
      this.showMileageField = false;
      this.obsDetailsForm.get('mileageOption').reset();
    }
  }

  calculateDuration() {
    if (this.obsDetailsForm) {
      const startTime = this.obsDetailsForm.get('startTime').value;
      const endTime = this.obsDetailsForm.get('endTime').value;
      if (startTime && endTime) {
        const start = new Date(`1970-01-01T${startTime}:00`);
        const end = new Date(`1970-01-01T${endTime}:00`);
        let duration = (end.getTime() - start.getTime()) / (1000 * 60);
        duration = parseFloat((duration / 60).toFixed(2));
        this.obsDetailsForm.get('duration').setValue(duration);
        this.obsDetailsForm.get('duration').markAsTouched();
      } else {
        this.obsDetailsForm.get('duration').setValue(null);
      }
    }
  }

  validateForm(): string[] {
    let errors = [];

    this.markFormGroupsAsTouched(this.obsDetailsForm);

    if (this.obsDetailsForm.invalid) {
      const errorMessages = {
        subject: this.getErrorMessage('subject'),
        visitationFormat: this.getErrorMessage('visitationFormat'),
        mileageOption: this.getErrorMessage('mileageOption'),
        startTime: this.getErrorMessage('startTime'),
        endTime: this.getErrorMessage('endTime'),
        duration: this.getErrorMessage('duration')
      };
  
      for (const controlName in errorMessages) {
        if (this.obsDetailsForm.get(controlName).errors) {
          errors.push(errorMessages[controlName]);
        }
      }
    }

    return errors;
  }

  markFormGroupsAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
      if (control instanceof FormGroup) {
        this.markFormGroupsAsTouched(control);
      }
    });
    formGroup.markAsTouched();
  }

  getErrorMessage(controlName: string): string {
    const control = this.obsDetailsForm.get(controlName);
    if (control.errors) {
      switch (controlName) {
        case 'subject':
          if (control.errors.required) {
            return 'Subject is required';
          }
          break;
        case 'visitationFormat':
          if (control.errors.required) {
            return 'Visitation Format is required';
          }
          break;
        case 'mileageOption':
          if (control.errors.required) {
            return 'Mileage Option is required';
          }
          break;
        case 'startTime':
          if (control.errors.required) {
            return 'Contact Start Time is required';
          }
          if (control.errors.timeOutOfRange) {
            return 'Contact Start Time must be between 7:00 AM and 4:30 PM';
          }
          break;
        case 'endTime':
          if (control.errors.required) {
            return 'Contact End Time is required';
          }
          if (control.errors.timeOutOfRange) {
            return 'Contact End Time must be between 7:00 AM and 4:30 PM';
          }
          break;
        case 'duration':
          if (control.errors.durationTooShort) {
            return `The observation must be at least ${this.minimumContactDuration} minutes.`;
          }
          break;
      }
    }
    return '';
  }

  durationValidator(control: AbstractControl) {
    const startTime = this.obsDetailsForm?.get('startTime')?.value;
    const endTime = this.obsDetailsForm?.get('endTime')?.value;
    if (startTime && endTime) {
      const start = new Date(`1970-01-01T${startTime}:00`);
      const end = new Date(`1970-01-01T${endTime}:00`);
      const duration = (end.getTime() - start.getTime()) / (1000 * 60);
      if (duration < this.minimumContactDuration) {
        return { durationTooShort: true };
      } else{
        return null;
      }
    }
    
    return { durationTooShort: true };
  }

  mileageOptionValidator(control: AbstractControl): ValidationErrors | null {
    let visitationFormat = this.obsDetailsForm?.get('visitationFormat');
    if (this.isFullTimeFieldSupervisor && 
        visitationFormat && 
        visitationFormat.value == VisitationFormatEnum.InPerson && 
        !control.value) {
      return { required: true };
    }
    return null;
  }

  timeRangeValidator(control: AbstractControl): ValidationErrors | null {
    const time = control.value;
    if (time) {
      const [hours, minutes] = time.split(':').map(Number);
      const totalMinutes = hours * 60 + minutes;
      const minTime = 7 * 60; // 7:00 AM in minutes
      const maxTime = 16 * 60 + 30; // 6:30 PM in minutes
      if (totalMinutes < minTime || totalMinutes > maxTime) {
        return { timeOutOfRange: true };
      }
    }
    return null;
  }

  onFieldBlur() {
    let hasError = false;
    Object.keys(this.obsDetailsForm.controls).forEach(field => {
      const control = this.obsDetailsForm.get(field);
      if (control.touched && control.errors) {
        hasError = true;
      }
    });
    this.requiredDetailsInvalid.emit(hasError);
  }
}