// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  margin-bottom: 16px;
}

.note-field {
  width: 100%;
}

.hint {
  text-align: right;
  display: block;
}

.color-grayblue {
  color: #324758;
}

.category-header {
  margin: 0px;
  font-weight: bold;
}

.question-description {
  font-size: 14px;
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1 100%;
}

.checkbox-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rating-card {
  padding: 15px;
}
.rating-card .rating-section-name {
  width: 40%;
  align-content: center;
}
.rating-card .rating-option {
  text-align: center;
}
.rating-card .rating-description {
  height: 40px;
}

@media (max-width: 768px) {
  .rating-card .row {
    flex-direction: column;
    padding: 0 2vw;
  }
  .rating-card .rating-option {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
  .rating-card .rating-section-name {
    text-align: center;
    width: 100%;
    margin-top: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/intern/obs-assessment/obs-domains/obs-domains.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACI,WAAA;EACA,iBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACI,aAAA;AACJ;AACI;EACI,UAAA;EACA,qBAAA;AACR;AAEI;EACE,kBAAA;AAAN;AAGI;EACE,YAAA;AADN;;AAKA;EACE;IACE,sBAAA;IACA,cAAA;EAFF;EAKA;IACE,WAAA;IACA,mBAAA;IACA,8BAAA;IACA,qBAAA;EAHF;EAMA;IACE,kBAAA;IACA,WAAA;IACA,eAAA;EAJF;AACF","sourcesContent":[".card {\n  margin-bottom: 16px;\n}\n\n.note-field {\n  width: 100%;\n}\n\n.hint {\n  text-align: right;\n  display: block;\n}\n\n.color-grayblue {\n  color: #324758;\n}\n\n.category-header {\n    margin: 0px;\n    font-weight: bold;\n}\n\n.question-description {\n    font-size: 14px;\n}\n\n.row {\n    display: flex;\n}\n\n.column {\n    display: flex;\n    flex-direction: column;\n    flex: 1 100%;\n}\n\n.checkbox-container {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.rating-card {\n    padding: 15px;\n\n    .rating-section-name {\n        width: 40%;\n        align-content: center;\n    }\n    \n    .rating-option {\n      text-align: center;\n    }\n\n    .rating-description {\n      height: 40px;\n    }\n}\n\n@media (max-width: 768px) {\n  .rating-card .row {\n    flex-direction: column;\n    padding: 0 2vw;\n  }\n  \n  .rating-card .rating-option {\n    width: 100%;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: baseline;\n  }\n\n  .rating-card .rating-section-name {\n    text-align: center;\n    width: 100%;\n    margin-top: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
