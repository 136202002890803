import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { FsObservation } from 'src/app/shared/models/fs-observation.model';
import { FieldSupervisor } from 'src/app/shared/models/fsupervisor.model';

@Component({
  selector: 'app-obs-conference',
  templateUrl: './obs-conference.component.html',
  styleUrls: ['./obs-conference.component.scss']
})
export class ObsConferenceComponent implements OnInit{
  @Input() obsAssessmentForm: FormGroup;
  @Input() fsObservation: FsObservation;
  @Input() completed: boolean;
  @Input() obsCoachName: string;
  @Input() contactDate: string;
  obsConferenceForm: FormGroup;
  preConferenceStartTimes = [];
  preConferenceEndTimes = [];
  postConferenceStartTimes = [];
  postConferenceEndTimes = [];
  fieldSupervisor: FieldSupervisor;
  finishing: boolean;

  constructor(private authService: AuthService,
    private globalService: GlobalService,
    private fb: FormBuilder) {}

  ngOnInit() {
    if (this.authService.authenticated) {
      if (this.globalService.supervisor != undefined) {
        this.fieldSupervisor = this.globalService.supervisor;
      }
    }

    this.initializeFormGroup();
    this.loadPreConferenceStartTimeDropdown();
    this.loadPostConferenceStartTimeDropdown();

    if (this.obsConferenceForm.get("preConferenceEndTime").value) {
      this.loadPreConferenceEndTimeDropdown(this.obsConferenceForm.get("preConferenceStartTime").value)
    }

    if (this.obsConferenceForm.get("postConferenceEndTime").value) {
      this.loadPostConferenceEndTimeDropdown(this.obsConferenceForm.get("postConferenceStartTime").value);
    }
        
    this.obsConferenceForm.get('preConferenceDate').valueChanges.subscribe(() => {
      this.obsConferenceForm.get('preConferenceEndTime').updateValueAndValidity();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contactDate && changes.contactDate.currentValue) {
      this.obsConferenceForm.get('preConferenceDate').updateValueAndValidity();
      this.obsConferenceForm.get('preConferenceEndTime').updateValueAndValidity();
      this.obsConferenceForm.get('postConferenceDate').updateValueAndValidity();
      this.obsConferenceForm.get('postConferenceStartTime').updateValueAndValidity
    }
  }

  private initializeFormGroup() {
    this.obsConferenceForm = this.fb.group({
      preConferenceDate: [this.fsObservation.preConferenceDate, [this.requiredOnFinishValidator.bind(this), this.preConferenceDateNotAfterContactDateValidator.bind(this)]],
      preConferenceStartTime: [this.fsObservation.preConferenceStartTime, this.requiredOnFinishValidator.bind(this)],
      preConferenceEndTime: [this.fsObservation.preConferenceEndTime, [this.requiredOnFinishValidator.bind(this), this.preConferenceEndTimeNotAfterContactStartTimeValidator.bind(this)]],
      postConferenceDate: [this.fsObservation.postConferenceDate, [this.requiredOnFinishValidator.bind(this), this.postConferenceDateNotInFutureValidator.bind(this), this.postConferenceDateNotBeforeContactDateValidator.bind(this)]],
      postConferenceStartTime: [this.fsObservation.postConferenceStartTime, [this.requiredOnFinishValidator.bind(this), this.postConferenceStartTimeNotBeforeContactEndTimeValidator.bind(this)]],
      postConferenceEndTime: [this.fsObservation.postConferenceEndTime, [this.requiredOnFinishValidator.bind(this)]],
      contactInfo: [this.fsObservation.contactInfo, this.requiredOnFinishValidator.bind(this)],
      fsDigitalSignature: [this.completed, this.requiredOnFinishValidator.bind(this)]
    });
    this.obsAssessmentForm.addControl('obsConference', this.obsConferenceForm);

    if (this.completed) {
      this.obsConferenceForm.disable();
    }
  }

  ngOnDestroy() {
    // Remove the form control from the parent form so that the child form controls are 
    // successfully reinitialized when the form is reloaded after being hidden.
    this.obsAssessmentForm.removeControl('obsConference');
  }

  loadPreConferenceStartTimeDropdown() {
    let isNoon = false;
    let starttime = 7.00;
    this.preConferenceStartTimes.push('7:00 AM');

    for (let index = 0; index < 59; index++) {
      starttime = starttime + 0.15
      if (starttime.toFixed(2).toString().indexOf('.60') != -1) {
        starttime = Math.round(starttime);
      }
      if (starttime == 12.00) {
        isNoon = true;
      }
      if (starttime == 13.00) {
        starttime = 1.00;
      }
      let formattedTime = starttime.toFixed(2).replace('.', ':');
      if (isNoon) {
        this.preConferenceStartTimes.push(formattedTime + ' PM');
      }
      else {
        this.preConferenceStartTimes.push(formattedTime + ' AM');
      }
    }
  }

  loadPreConferenceEndTimeDropdown(preConfStartTime) {
    let isNoon = false;
    let isAfternoon = false;

    let startTimeFormatted = preConfStartTime.replace(':', '.');
    this.preConferenceEndTimes = [];

    if (startTimeFormatted.indexOf('PM') != -1) {
      if (parseFloat(startTimeFormatted.substring(0, 3)) == 12) {
        isNoon = true;
      }
      else {
        isAfternoon = true;
      }
    }

    let starttime = parseFloat(startTimeFormatted.substring(0, startTimeFormatted.indexOf(' ')));
    this.preConferenceEndTimes.push(preConfStartTime);

    for (let index = 0; index <= 59; index++) {
      starttime = starttime + 0.15
      if (starttime.toFixed(2).toString().indexOf('.60') != -1) {
        starttime = Math.round(starttime);
      }
      if (starttime == 12.00) {
        isNoon = true;
      }
      if (starttime == 13.00) {
        isAfternoon = true;
        starttime = 1.00;
      }
      let formattedTime = starttime.toFixed(2).replace('.', ':');
      if (isNoon || isAfternoon) {
        if (isAfternoon && starttime > 10.00) {
          break;
        }
        this.preConferenceEndTimes.push(formattedTime + ' PM');
      }
      else {
        this.preConferenceEndTimes.push(formattedTime + ' AM');
      }
    }
  }

  loadPostConferenceStartTimeDropdown() {
    let starttime = 7.00;
    this.postConferenceStartTimes.push('7:00 AM');

    for (let index = 0; index < 19; index++) {
      starttime = starttime + 0.15
      if (starttime.toFixed(2).toString().indexOf('.60') != -1) {
        starttime = Math.round(starttime);
      }
      let formattedTime = starttime.toFixed(2).replace('.', ':');
      this.postConferenceStartTimes.push(formattedTime + ' AM');
    }

    for (let index = 20; index <= 59; index++) {
      starttime = starttime + 0.15
      if (starttime.toFixed(2).toString().indexOf('.60') != -1) {
        starttime = Math.round(starttime);
      }
      if (starttime == 13.00) {
        starttime = 1.00;
      }
      let formattedTime = starttime.toFixed(2).replace('.', ':');
      this.postConferenceStartTimes.push(formattedTime + ' PM');
    }
  }

  loadPostConferenceEndTimeDropdown(postConfStartTime) {
    let isNoon = false;
    let isAfternoon = false;

    let startTimeFormatted = postConfStartTime.replace(':', '.');
    this.postConferenceEndTimes = [];

    if (startTimeFormatted.indexOf('PM') != -1) {
      if (parseFloat(startTimeFormatted.substring(0, 3)) == 12) {
        isNoon = true;
      }
      else {
        isAfternoon = true;
      }
    }

    let starttime = parseFloat(startTimeFormatted.substring(0, startTimeFormatted.indexOf(' ')));
    this.postConferenceEndTimes.push(postConfStartTime);

    for (let index = 0; index <= 59; index++) {
      starttime = starttime + 0.15
      if (starttime.toFixed(2).toString().indexOf('.60') != -1) {
        starttime = Math.round(starttime);
      }
      if (starttime == 12.00) {
        isNoon = true;
      }
      if (starttime == 13.00) {
        isAfternoon = true;
        starttime = 1.00;
      }
      let formattedTime = starttime.toFixed(2).replace('.', ':');
      if (isNoon || isAfternoon) {
        if (isAfternoon && starttime > 10.00) {
          break;
        }
        this.postConferenceEndTimes.push(formattedTime + ' PM');
      }
      else {
        this.postConferenceEndTimes.push(formattedTime + ' AM');
      }
    }
  }

  disablePreConferenceEndTime() {
    let preConfStartTime = this.obsConferenceForm.get("preConferenceStartTime").value;
    return (preConfStartTime == '' || preConfStartTime == null);
  }

  disablePostConferenceEndTime() {
    let postConfStartTime = this.obsConferenceForm.get("postConferenceStartTime").value;
    return (postConfStartTime == '' || postConfStartTime == null);
  }
  

  getFSDigitalSignature() {
    if (this.completed && this.obsCoachName) {
      return this.obsCoachName;
    }
    else{
      return this.fieldSupervisor.displayName;
    }
  }

  validateForm(isFinishing: boolean = false): string[] {
      let errors = [];
      this.finishing = isFinishing;
      this.markFormGroupsAsTouched(this.obsConferenceForm);
  
      this.updateValidators();
  
      if (this.finishing && this.obsConferenceForm.invalid) {
        const errorMessages = {
          preConferenceDate: this.getErrorMessage('preConferenceDate'),
          preConferenceStartTime: this.getErrorMessage('preConferenceStartTime'),
          preConferenceEndTime: this.getErrorMessage('preConferenceEndTime'),
          postConferenceDate: this.getErrorMessage('postConferenceDate'),
          postConferenceStartTime: this.getErrorMessage('postConferenceStartTime'),
          postConferenceEndTime: this.getErrorMessage('postConferenceEndTime'),
          contactInfo: this.getErrorMessage('contactInfo'),
          fsDigitalSignature: this.getErrorMessage('fsDigitalSignature')
        };
    
        for (const controlName in errorMessages) {
          if (this.obsConferenceForm.get(controlName).errors) {
            errors.push(errorMessages[controlName]);
          }
        }
      } else {
        this.finishing = false;
      }
  
      return errors;
    }
  
    updateValidators() {
      this.obsConferenceForm.get('preConferenceDate').updateValueAndValidity();
      this.obsConferenceForm.get('preConferenceStartTime').updateValueAndValidity();
      this.obsConferenceForm.get('preConferenceEndTime').updateValueAndValidity();
      this.obsConferenceForm.get('postConferenceDate').updateValueAndValidity();
      this.obsConferenceForm.get('postConferenceStartTime').updateValueAndValidity();
      this.obsConferenceForm.get('postConferenceEndTime').updateValueAndValidity();
      this.obsConferenceForm.get('contactInfo').updateValueAndValidity();
      this.obsConferenceForm.get('fsDigitalSignature').updateValueAndValidity();
    }
  
    markFormGroupsAsTouched(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        control.markAsTouched({ onlySelf: true });
        if (control instanceof FormGroup) {
          this.markFormGroupsAsTouched(control);
        }
      });
      formGroup.markAsTouched();
    }    

    getErrorMessage(controlName: string): string {
      const control = this.obsConferenceForm.get(controlName);
      if (control.errors) {
        switch (controlName) {
          case 'preConferenceDate':
            if (control.errors.required) {
              return 'Pre-Conference date is required';
            }
            if (control.errors.preConferenceDateAfterObservation) {
              return 'Pre-Conference date cannot occur after the contact date';
            }
            break;
          case 'preConferenceStartTime':
            if (control.errors.required) {
              return 'Pre-Conference start time is required';
            }
            break;
          case 'preConferenceEndTime':
            if (control.errors.required) {
              return 'Pre-Conference end time is required';
            }
            if (control.errors.preConferenceEndTimeAfterStartTime) {
              return 'Pre-Conference end time cannot occur after the contact start time';
            }
            break;
          case 'postConferenceDate':
            if (control.errors.required) {
              return 'Post-Conference date is required';
            }
            if (control.errors.dateInFuture) {
              return 'Post-Conference date cannot occur in the future';
            }
            if (control.errors.postConferenceDateBeforeContactDate) {
              return 'Post-Conference date cannot occur before the contact date';
            }
            break;
          case 'postConferenceStartTime':
            if (control.errors.required) {
              return 'Post-Conference start time is required';
            }
            if (control.errors.postConferenceStartTimeBeforeContactEndTime) {
              return 'Post-Conference start time cannot occur before the contact end time';
            }
            break;
          case 'postConferenceEndTime':
            if (control.errors.required) {
              return 'Post-Conference end time is required';
            }
            break;
          case 'contactInfo':
          if (control.errors.required) {
            return 'Contact Info is required';
          }
          break;
          case 'fsDigitalSignature':
          if (control.errors.required) {
            return 'Digital signature check is required';
          }
          break;
        }
      }
      return '';
    }
  
    requiredOnFinishValidator(control: AbstractControl): ValidationErrors | null {
      if (this.finishing && !control.value) {
        return { required: true };
      }
      return null;
    }
  
    preConferenceDateNotAfterContactDateValidator(control: AbstractControl): ValidationErrors | null {
      if (this.finishing && control.value && this.contactDate) {

        const preConferenceDate = new Date(control.value);
        const contactDate = new Date(`${this.contactDate}T00:00:00`);
    
        if (preConferenceDate > contactDate) {
          return { preConferenceDateAfterObservation: true };
        }
      }
      return null;
    }

    postConferenceDateNotInFutureValidator(control: AbstractControl): ValidationErrors | null {
      if (this.finishing && control.value) {
        const inputDate = new Date(control.value);
        const currentDate = new Date();
    
        if (inputDate > currentDate) {
          return { dateInFuture: true };
        }
      }
      return null;
    }

    postConferenceDateNotBeforeContactDateValidator(control: AbstractControl): ValidationErrors | null {
      if (this.finishing && control.value && this.contactDate) {
        const postConferenceDate = new Date(control.value);
        const contactDate = new Date(this.contactDate);
    
        if (postConferenceDate < contactDate) {
          return { postConferenceDateBeforeContactDate: true };
        }
      }
      return null;
    }

    preConferenceEndTimeNotAfterContactStartTimeValidator(control: AbstractControl): ValidationErrors | null {
      if (this.finishing && control.value) {
        const preConferenceDate = this.obsConferenceForm.get('preConferenceDate').value;
        const preConferenceEndTime = this.convertTo24HourFormat(control.value);
        const contactDate = this.contactDate;
        const contactStartTime = this.obsAssessmentForm.get('obsDetails')?.get('startTime').value;

        if (preConferenceDate && contactDate && contactStartTime && preConferenceEndTime) {
          const formattedPreConferenceDate = this.formatDate(preConferenceDate);

          const startTime = new Date(`${contactDate}T${contactStartTime}:00`);
          const endTime = new Date(`${formattedPreConferenceDate}T${preConferenceEndTime}:00`);

          if (endTime > startTime) {
            return { preConferenceEndTimeAfterStartTime: true };
          }
        }
      }
      return null;
    }

    postConferenceStartTimeNotBeforeContactEndTimeValidator(control: AbstractControl): ValidationErrors | null {
      if (this.finishing && control.value) {
        const postConferenceDate = this.obsConferenceForm.get('postConferenceDate').value;
        const postConferenceStartTime = this.convertTo24HourFormat(this.obsConferenceForm.get('postConferenceStartTime').value);
        const contactDate = this.contactDate;
        const contactEndTime = this.obsAssessmentForm.get('obsDetails')?.get('endTime').value;
    
        if (postConferenceDate && contactDate && contactEndTime && postConferenceStartTime) {
          const formattedPostConferenceDate = this.formatDate(postConferenceDate);
    
          const endTime = new Date(`${contactDate}T${contactEndTime}:00`);
          const postStartTime = new Date(`${formattedPostConferenceDate}T${postConferenceStartTime}:00`);
    
          if (postStartTime <= endTime) {
            return { postConferenceStartTimeBeforeContactEndTime: true };
          }
        }
      }
      return null;
    }

    private convertTo24HourFormat(time: string) : string{
      const [timePart, modifier] = time.split(' ');
      let [hours, minutes] = timePart.split(':').map(Number);

      if (modifier === 'PM' && hours !== 12) {
        hours += 12;
      } else if (modifier === 'AM' && hours === 12) {
        hours = 0;
      }

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    private formatDate(date: string): string {
      return new Date(date).toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    }
}
