import { Component, OnInit, ViewChild, ChangeDetectorRef, Output } from '@angular/core';
import { InternService } from '../../services/intern.service';
import { certprogram } from 'src/app/shared/models/certprogram';
import { ModalService } from 'src/app/services/modal.service';
import { Subject, Observable, concat } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { internCertDetails } from 'src/app/shared/models/internCertDetails';
import { Router, ActivatedRoute } from '@angular/router';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { EmailModel } from '../../shared/models/email-model';
import { EmailRecipient } from '../../shared/models/email-recipient';
import { EmailComponent } from '../../shared/email/email.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { ConfigService } from '../../services/config.service';
import { FieldSupervisorCandidateDTO } from 'src/app/shared/models/fieldSupervisorCandidateDTO';
import { FSupervisorService } from 'src/app/services/supervisor.service';
import { AbstractControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { campus } from 'src/app/shared/models/campus';
import { Intern } from 'src/app/shared/models/intern';
import { internDetails } from 'src/app/shared/models/internDetails';
import { fsContacts } from 'src/app/shared/models/fsContacts';
import { RoleValue } from 'src/app/shared/models/role.model';
import { InternWhatsNextComponent, InternWhatsNextData } from '../intern-whats-next/intern-whats-next.component';
import { saveAs } from 'file-saver';
import { ContactTypeEnum } from '../../shared/enums/contact-type.enum';
import { ObservationMethodEnum } from 'src/app/shared/enums/obs-method.enum';
import { ContactScheduleCalculator } from 'src/app/shared/models/contact-schedule-calculator';
import { PaymentDeadlineStatus } from 'src/app/shared/enums/payment-deadline-status.enum';

@Component({
  selector: 'app-intern-details',
  templateUrl: './intern-details.component.html',
  styleUrls: ['./intern-details.component.scss']
})
export class InternDetailsComponent implements OnInit {

  fSupervisorId: number;
  internId: string;
  @Output()
  lstcert: internCertDetails;
  showError: boolean = false;
  showLoading: boolean = false;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<certprogram> = new Subject();
  durationInSeconds = 5;
  isClassRoomSupport: boolean = false;
  assignedCandidates: FieldSupervisorCandidateDTO[];
  assignedCandidatesFiltered: Observable<FieldSupervisorCandidateDTO[]>;
  jumpToInternForm: UntypedFormGroup;
  sortedFsContacts: fsContacts[];
  isFieldSupervisorImpersonator: boolean;
  isFieldSupervisor: boolean;
  approvedObservations = 0;
  observations: fsContacts[];
  contacts: fsContacts[];
  informalObservations: fsContacts[];
  conferences: fsContacts[];
  dueBetween30and60Days: boolean;
  dueOver60Days: boolean;

  constructor(private modalService: ModalService, private router: Router, private internService: InternService,
    private activatedRoute: ActivatedRoute, private cdRef: ChangeDetectorRef, private _snackBar: MatSnackBar,
    public dialog: MatDialog, private authService: AuthService, private globalService: GlobalService,
    private configService: ConfigService, private fsService: FSupervisorService, private fb: UntypedFormBuilder
  ) {
    this.lstcert = new internCertDetails;

    this.lstcert.Intern = new internDetails;
    this.lstcert.Recommendations = [];
    this.lstcert.Campus = new campus();
    this.lstcert.Certificates = []
    this.lstcert.FsContacts = [];
  }

  ngOnInit() {
    if (!this.authService.authenticated) {
      this.router.navigate(['auth']);
    }

    if (this.authService.authenticated) {
      if (this.globalService.supervisor != undefined) {
        this.fSupervisorId = this.globalService.supervisor.FieldSupervisorId;
      }
    }

    this.jumpToInternForm = this.fb.group({
      jumpToInternControl: ['']
    });


    this.populateInternSearch(this.fSupervisorId);

    this.internId = this.activatedRoute.snapshot.paramMap.get('id');

    this.getInternsDetails();

    this.dtOptions = {
      paging: false,
      searching: false,
      ordering: true,
      info: false,
      order: []
    };

    var roles = JSON.parse(localStorage.getItem("fsRoles"));
    console.log(roles);
    for (var i = 0; i < roles.length; i++) {
      if (roles[i].AuthorizationRoleID == RoleValue.CS_Advisor || roles[i].AuthorizationRoleID == RoleValue.CS_Advisor_Manager) { // Classroom support can edit even completed observations
        this.isClassRoomSupport = true;
      }
      if (roles[i].AuthorizationRoleID == RoleValue.Coach_Impersonator) { // Field Supervisor Impersonator can edit any observation
        this.isFieldSupervisorImpersonator = true;
        break;
      }
      if (roles[i].AuthorizationRoleID == RoleValue.Field_Supervisor) { // Field Supervisor can edit Saved, Scheduled or Rejected observation
        this.isFieldSupervisor = true;
        break;
      }
    }
  }

  /*
     Begin logic for Smart search
  */
  populateInternSearch(supervisorId: number) {

    this.fsService.getCandidatesForFieldSupervisor(supervisorId).subscribe((data: FieldSupervisorCandidateDTO[]) => {
      this.assignedCandidates = data;

      this.assignedCandidatesFiltered = this.jumpToInternForm.controls.jumpToInternControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filteredCandidates(value))
      );
    });
  }


  displayFnCertArea(certAreaObj: FieldSupervisorCandidateDTO) {
    return certAreaObj ? certAreaObj.internId : undefined;
  }

  _filteredCandidates(value: any): any {
    if (!value || typeof value !== 'string') {
      return this.assignedCandidates;
    }

    const filterValue = value.toString().toLowerCase();
    return this.assignedCandidates.filter(option => {
      const { internId, firstName, lastName } = option;
      const combinedString = `${firstName} ${lastName} (${internId})`.toLowerCase();
      return internId.toLowerCase().includes(filterValue) ||
        firstName.toLowerCase().includes(filterValue) ||
        lastName.toLowerCase().includes(filterValue) ||
        combinedString.includes(filterValue);
    });
  }
  /*
    End of logic for Smart search
  */


  getInternsDetails() {
    this.showLoading = true;
    this.lstcert = new internCertDetails;
    this.internService.getInternDetailsById(this.internId, this.fSupervisorId).subscribe((data: internCertDetails) => {
      this.showError = false;
      this.showLoading = false;
      this.lstcert = data;
      
      //Set the payment deadline status
      this.dueBetween30and60Days = this.lstcert.Intern.ProgramPaymentDeadlineStatus != PaymentDeadlineStatus.UnderThirtyDays 
                                    && this.lstcert.Intern.ProgramPaymentDeadlineStatus != PaymentDeadlineStatus.OverSixtyDays
                                    && this.lstcert.Intern.ProgramPaymentDeadlineStatus != PaymentDeadlineStatus.Undetermined
                                    && this.lstcert.Intern.ProgramPaymentDeadlineStatus != PaymentDeadlineStatus.ExemptPayment;

      this.dueOver60Days = this.lstcert.Intern.ProgramPaymentDeadlineStatus == PaymentDeadlineStatus.OverSixtyDays;

      this.lstcert.FsContacts.forEach(contact => {
        const downloadMethod = this.getDownloadMethod(this.lstcert.Intern.profileState, contact.ObsTypeId);
        contact.pdfURL = `${this.configService.apiUrl}/v1.0/${downloadMethod}/${contact.AssessmentId}`;
      });

      if (!this.lstcert.Intern) {
        this.showError = true;
      }

      //Map Contact Recipients to Separated by Commas field
      this.lstcert.FsContacts.forEach(contact => {

        if(contact.contactRecipients){
          let allContactRecipients = contact.contactRecipients.map(recipient => recipient.recipientDescription);
          contact.compositeContactRecipients = allContactRecipients.join(', ');
        }
      });

      this.cdRef.detectChanges();
      this.sortFsContactsByContactType(this.lstcert.FsContacts);
      this.approvedObservations = this.observations?.filter(obs => obs.ObsStatus == "Approved").length;

      this.lstcert.Certificates.forEach(cert => {
        const allObservations = cert.Observations;
        const startDateCalcualtor =  new ContactScheduleCalculator();

        cert.Conferences = allObservations.filter(obs => obs.ObsMethodId == ObservationMethodEnum.Conference);
        startDateCalcualtor.calculateObservationStartDates(cert.Conferences, new Date(cert.TrackStart));

        cert.InformalObservations = allObservations.filter(obs => obs.ObsMethodId == ObservationMethodEnum.InformalObservation);
        startDateCalcualtor.calculateObservationStartDates(cert.InformalObservations, new Date(cert.TrackStart));

        cert.Observations = allObservations.filter(obs => obs.ObsMethodId == ObservationMethodEnum.Observation);
        startDateCalcualtor.calculateObservationStartDates(cert.Observations, new Date(cert.TrackStart));
      });

    }, (error) => { console.log(error) }, () => {
      this.showLoading = false;
    });
  }

  private getDownloadMethod(profileState, obsTypeId) {
    if (obsTypeId == ObservationMethodEnum.InformalObservation) {
      return 'observation';
    }
    if (obsTypeId == ObservationMethodEnum.Conference) {
      return 'conference';
    }
    return profileState === 'Texas' ? 'GetObsAssessmentPDF' : 'GetExpansionStateObsAssessmentPDF';
    }

  private sortFsContactsByContactType(unsortedFsContacts: Array<fsContacts>): void {
    if (unsortedFsContacts == null) {
      return;
    }

    //Filter out those contacts that are still in scheduled status
    unsortedFsContacts = unsortedFsContacts.filter(contact => contact.ObsStatus !== 'Scheduled');

    //Separate contacts by type
    this.observations = unsortedFsContacts.filter(contact => contact.ObsTypeId === ContactTypeEnum.Observation).sort((a, b) => new Date(a.ScheduledDate).getTime() - new Date(b.ScheduledDate).getTime());
    this.informalObservations = unsortedFsContacts.filter(contact => contact.ObsTypeId === ContactTypeEnum.InformalObservation).sort((a, b) => new Date(a.ScheduledDate).getTime() - new Date(b.ScheduledDate).getTime());
    this.conferences = unsortedFsContacts.filter(contact => contact.ObsTypeId === ContactTypeEnum.Conference).sort((a, b) => new Date(a.ScheduledDate).getTime() - new Date(b.ScheduledDate).getTime());
    this.contacts = unsortedFsContacts.filter(contact => (contact.ObsTypeId !== ContactTypeEnum.Observation 
                                                            && contact.ObsTypeId !== ContactTypeEnum.InformalObservation
                                                            && contact.ObsTypeId !== ContactTypeEnum.Conference
                                                          )).sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());
  }

  downloadPDF() {
    var data = document.getElementById('internDataPDF');
    data.style.display = "block";
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 195;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 10;
      pdf.addImage(contentDataURL, 'PNG', 10, position, imgWidth, imgHeight);

      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('InterDetails.pdf'); // Generated PDF
      data.style.display = "none";
    });
  }

  downloadFromURL(url: string, observationType: string) {
    console.log('url', url);
    const firstName = this.lstcert.Intern.FirstName;
    const lastName = this.lstcert.Intern.LastName;
    let name = firstName + '_' + lastName;
    name = name.replace(' ', '_');
    const observationName = observationType.replace(' ', '_');
    const fileName = name + '_' + observationName + '.pdf';

    this.internService.downloadFromURL(url).toPromise().then((blob) => {
      saveAs(blob, fileName);
    });

  }


  gotoIntern(selectedIntern: FieldSupervisorCandidateDTO) {
    this.router.navigate(['/intern-details', selectedIntern.internId]);
    this.internId = selectedIntern.internId;
    if (this.internId) {
      this.getInternsDetails();
      this.jumpToInternForm.controls.jumpToInternControl.setValue('');
    } else {
      this.showError = true;
    }    
  }

  openNewContact(stateValue: string) {
    let avlvalue;

    if (stateValue === 'Texas') {
      avlvalue = true;
    }
    else {
      avlvalue = false
    }

    this.router.navigate(['/observation-details', this.internId, { data: avlvalue }]);
  }

  openEditContact(assessmentid, completed) {
    console.log("Obs Completed status", completed + " " + assessmentid);
    sessionStorage.setItem(assessmentid, completed); // use storage so user can't change url parameter.
    this.router.navigate(['/observation-details', this.internId, { assesst: assessmentid, completed: completed }]);
  }

  printPDF(assessmentid) {

    console.log("Calling method to get PDF");

    this.internService.DownloadObservation(assessmentid).subscribe(data => {

      var sampleArr = this.base64ToArrayBuffer(data);
      this.saveByteArray("Observation.pdf", sampleArr);

    });
  }

  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  saveAs(blob, fileName) {
    var url = window.URL.createObjectURL(blob);

    var anchorElem = document.createElement("a");
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();

    document.body.removeChild(anchorElem);

    // On Edge, revokeObjectURL should be called only after
    // a.click() has completed, atleast on EdgeHTML 15.15048
    setTimeout(function () {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  opensendemail() {

    // create email model to pass as a data to email component
    let mailModel = new EmailModel;

    let recipient = new EmailRecipient();
    recipient.email = this.lstcert.Intern.Email;
    recipient.name = this.lstcert.Intern.FirstName + ' ' + this.lstcert.Intern.LastName;
    mailModel.bcc.push(recipient);

    //Map sender
    let fromRecipient = new EmailRecipient();
    mailModel.from = fromRecipient;

    //Open email Modal component
    const dialogRef = this.dialog.open(EmailComponent, {
      width: '800px',
      height: '750px',
      minHeight: '400px',
      minWidth: '600px',
      data: mailModel
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.openSnackBar("Email Sent", "Successfully");

      }
    });
  }

  openInternWhatsNext() {
    const data = new InternWhatsNextData();
    data.internId = this.internId;
    data.internState = this.lstcert.Intern.State;

    const dialogRef = this.dialog.open(InternWhatsNextComponent, {
      width: '450px',
      height: '500px',
      minHeight: '500px',
      minWidth: '450px',
      data: data
    });

  }

  closeModal() {
    this.modalService.close('email-selected-interns');
  }

  sendEmail() {
  }

  email() {
  }

  addDays(date: Date, days: number) {
    let result = new Date(date);
    return result.setDate(result.getDate() + days);
  }

  //Generates a snackBar notification
  openSnackBar(data: string, status: string) {
    this._snackBar.open(data, status, {
      duration: this.durationInSeconds * 1500,
    });
  }

  isAnObservationContact(fscont: fsContacts): boolean {
    return Number.isInteger(fscont?.ObsTypeId)
      ? (fscont?.ObsTypeId === ContactTypeEnum.Observation || fscont?.ObsTypeId === ContactTypeEnum.InformalObservation)
      : fscont?.ObsType?.includes('Observation');
  }

  isAConferenceContact(fscont: fsContacts): boolean {
    return Number.isInteger(fscont?.ObsTypeId)
      ? fscont?.ObsTypeId === ContactTypeEnum.Conference
      : fscont?.ObsType?.includes('Conference');
  }

  private canDownloadPDF(fscont: fsContacts) {
    return (this.isAnObservationContact(fscont) || this.isAConferenceContact(fscont))
    && (fscont?.ObsStatus == 'Submitted' || fscont?.ObsStatus == 'Approved' || fscont?.ObsStatus == 'Accepted' || fscont?.ObsStatus == 'Paid');
  }
  private canViewContact(fscont: fsContacts) {
    return (this.isAnObservationContact(fscont) || this.isAConferenceContact(fscont))
    && (fscont?.ObsCompleted == true && this.isClassRoomSupport == false);
  }

  private canEditContact(fscont: fsContacts) {
    let isEditableContact = this.isAnObservationContact(fscont) || this.isAConferenceContact(fscont);
    let isEditable = fscont?.ObsCompleted == false || this.isClassRoomSupport || this.isFieldSupervisorImpersonator;
    let isEditableByFS = this.isFieldSupervisor && (fscont?.ObsStatus == 'Saved' || fscont?.ObsStatus == 'Scheduled' || fscont?.ObsStatus == 'Rejected');
    return isEditableContact && (isEditable || isEditableByFS);
  }
}
