// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.overlay {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.286);
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.container-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.286);
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/spinner-overlay/spinner-overlay.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EAGA,4CAAA;AACJ;;AAEA;EACE,aAAA;EACA,WAAA;EACA,sCAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sCAAA;EACA,WAAA;AACF","sourcesContent":[".center {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    -moz-transform: translateX(-50%) translateY(-50%);\n    -webkit-transform: translateX(-50%) translateY(-50%);\n    transform: translateX(-50%) translateY(-50%);\n}\n\n.overlay{\n  height:100vh;\n  width:100%;\n  background-color:rgba(0, 0, 0, 0.286);\n  z-index:    10;\n  top:        0; \n  left:       0; \n  position:   fixed;\n}\n\n.container-overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.286);\n  z-index: 10;\n}\n\n// :host ::ng-deep .mat-mdc-progress-spinner circle {   \n//     stroke: $color-regal-blue;\n// }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
