// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-container {
  width: 190px;
  height: 20px;
  border-radius: 15px;
  background-color: #faf9f9;
  border: 1px solid #20ace7;
  position: relative;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  border-radius: 15px 0 0 15px;
  background-color: #C8E4EE;
  transition: width 0.5s ease-in-out;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #003A71;
}`, "",{"version":3,"sources":["webpack://./src/app/intern/intern-whats-next/progress-bar/progress-bar.component.scss"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,4BAAA;EACA,yBAAA;EACA,kCAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,eAAA;EACA,cAAA;AAAJ","sourcesContent":["\n.progress-container {\n    width: 190px;\n    height: 20px;\n    border-radius: 15px;\n    background-color: #faf9f9;\n    border: 1px solid #20ace7;\n    position: relative;\n    overflow: hidden;\n}\n\n.progress-bar {\n    height: 100%;\n    border-radius: 15px 0 0 15px;\n    background-color: #C8E4EE;\n    transition: width 0.5s ease-in-out;\n}\n\n.progress-text {\n    position: absolute;\n    width: 100%;\n    text-align: center;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 12px;\n    color: #003A71;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
