import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InternSearchComponent } from './intern/intern-search/intern-search.component';
import { InternDetailsComponent } from './intern/intern-details/intern-details.component';
import { ObservationDetailsComponent } from './intern/observation/observation-details/observation-details.component';
import { NontexasContactComponent } from './intern/non-texas/nontexas-contact/nontexas-contact.component';
import { AuthComponent } from './auth/auth.component';
import { SelectSupervisorComponent } from './auth/select-supervisor/select-supervisor.component';
import { LockoutGuard } from './auth/lockout.guard';
import { LockoutNotificationComponent } from './auth/lockout-notification/lockout-notification.component';
import { ObsAssessmentComponent } from './intern/obs-assessment/obs-assessment.component';

//routes that are not under lockout restrictions
const allowedRoutes: Routes =
    [
        {
            path: 'lockout-notification',
            component: LockoutNotificationComponent
        },
        {
            path: 'auth',
            component: AuthComponent
        },
        {
            path: 'select-supervisor',
            component: SelectSupervisorComponent
        }
    ];

const routes: Routes = [
    {
        path: '',
        canActivate: [LockoutGuard],
        children: [{
            path: 'intern-search',
            component: InternSearchComponent,
            pathMatch: 'full'
        },
        {
            path: 'intern-search/:supervisorId',
            component: InternSearchComponent,
            pathMatch: 'full'
        },
        {
            path: 'intern-details/:id',
            component: InternDetailsComponent
        },
        {
            path: '',
            redirectTo: 'auth',
            pathMatch: 'full'
        },
        {
            path: 'internDetails',
            component: InternDetailsComponent,
            outlet: 'popup'

        },
        {
            path: 'observation-details/:id',
            component: ObservationDetailsComponent,
            pathMatch: 'full'
        },
        {
            path: 'assessment/:id',
            component: ObsAssessmentComponent,
            pathMatch: 'full'
        },
        {
            path: 'nontexas-contact/:id',
            component: NontexasContactComponent
        },
        {
            path: 'auth',
            component: AuthComponent
        }]
    }

];

@NgModule({
    imports: [
        RouterModule.forRoot(allowedRoutes, {}),
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
