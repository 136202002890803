import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { Assessment } from '../shared/models/assessment';
import { AssessmentAttachment } from '../shared/models/assessment-attachment.model';
import { AssessmentOption } from '../shared/models/assessmentOption';
import { MileageOption } from './../shared/models/mileageOption';
import { AuthService } from './auth.service';
import { VisitationFormat } from '../shared/models/visitation-format';
import { Result } from '../shared/models/result.model';

@Injectable({
  providedIn: 'root'
})
export class ObservationService {

  constructor(private httpClient: HttpClient,
    private snackBar: MatSnackBar,
    private configService: ConfigService,
    private authService: AuthService
  ) { }

  public SaveObsContact(obsContact: Assessment) {

    //console.log('attempting to save new Observation contact');
    //let headersToPass = new HttpHeaders();
    //headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8').set('x-functions-key', 'iRxB9wpaynlZkCuN3yQJaU/WKdZ6sCT2Cp0oeUmmQ39LYO9KyWIUDg==');

    return this.httpClient.put<Assessment>(`${this.configService.apiUrl}/v1.0/Assessment`, obsContact).pipe(
      catchError(error => {
        this.snackBar.open("There was an error while saving your observation.", undefined, {
          panelClass: 'alert-red', verticalPosition: 'top', duration: 5000
        });
        console.log(error);
        return throwError(error);
      })
    );
  }

  public FinishObsContact(obsContact: Assessment) {

    return this.httpClient.post<boolean | string[]>(`${this.configService.apiUrl}/v1.0/Assessment`, obsContact).pipe(
      catchError(error => {
        this.snackBar.open("There was an error while saving your observation.", undefined, {
          panelClass: 'alert-red', verticalPosition: 'top', duration: 5000
        });
        console.log(error);
        return throwError(error);
      })
    );
  }

  public GetAssessment(id: string) {

    //let headersToPass = new HttpHeaders();
    //headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8').set('x-functions-key', 'iRxB9wpaynlZkCuN3yQJaU/WKdZ6sCT2Cp0oeUmmQ39LYO9KyWIUDg==');

    return this.httpClient.get<Assessment>(`${this.configService.apiUrl}/v1.0/Assessment/${id}`).pipe(
      retry(3),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public GetMileageOptions() {
    return this.httpClient.get<Array<MileageOption>>(`${this.configService.apiUrl}/ObsAssessment/GetMileageOptions`).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public GetAssessmentOptions() {
    return this.httpClient.get<Array<AssessmentOption>>(`${this.configService.apiUrl}/ObsAssessment/GetAssessmentOptions`).pipe(
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      shareReplay()
    );
  }

  public GetVisitationFormatOptions(): Observable<Array<VisitationFormat>> {
    return this.httpClient.get<Array<VisitationFormat>>(`${this.configService.apiUrl}/v1.0/VisitationFormats`).pipe(
      catchError(error => {
        console.error(error);
        return EMPTY;
      }),
      shareReplay()
    )
  }

  public GetAssessmentTypeOptions(): Observable<Array<AssessmentOption>> {
    return this.httpClient.get<Array<AssessmentOption>>(`${this.configService.apiUrl}/v1.0/AssessmentTypes`).pipe(
      catchError(error => {
        console.error(error);
        return EMPTY;
      }),
      shareReplay()
    )
  }

  public UploadAttachment(data: FormData) {
    const formHeaders = new HttpHeaders();
    //formHeaders.set('Content-Type', 'multipart/form-data');
    formHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.httpClient.post<AssessmentAttachment>(`${this.configService.apiUrl}/assessment/attachment`, data, { headers: formHeaders });
  }
  public DeleteAttachment(observationId: number) {
    return this.httpClient.delete(`${this.configService.apiUrl}/assessment/attachment/${observationId}`,);
  }

  public ReplaceAttachment(data: FormData) {
    const formHeaders = new HttpHeaders();
    //formHeaders.set('Content-Type', 'multipart/form-data');
    formHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.httpClient.put<AssessmentAttachment>(`${this.configService.apiUrl}/assessment/attachment`, data, { headers: formHeaders });
  }
  public DownloadAttachment(obsAssessmentID: number) {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/pdf');
    return this.httpClient.get<any>(`${this.configService.apiUrl}/assessment/attachment/${obsAssessmentID}`, { headers: headers, responseType: 'blob' as 'json' });
  }

  public GetObsForm(stateId: string): Observable<Result> {
    return this.httpClient.get<Result>(`${this.configService.apiUrl}/v1.0/state/${stateId}/observation-form`);
  }
}
