import { Component, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ObservationService } from 'src/app/services/observation.service';
import { ContactTypeEnum } from 'src/app/shared/enums/contact-type.enum';
import { ObsForm } from 'src/app/shared/models/obs-form.model';
import { ObservationDetailsComponent } from '../observation/observation-details/observation-details.component';
import { internCertDetails } from 'src/app/shared/models/internCertDetails';
import { InternService } from 'src/app/services/intern.service';
import { GlobalService } from 'src/app/services/global.service';
import { FieldSupervisor } from 'src/app/shared/models/fsupervisor.model';
import { observations } from 'src/app/shared/models/observations';
import { StateEnum } from 'src/app/shared/enums/state.enum';
import { campus } from 'src/app/shared/models/campus';
import { FsContact } from 'src/app/shared/models/fs-contact.model';
import { FSContactsService } from 'src/app/services/fs-contacts.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ObsQuestionResponse } from 'src/app/shared/models/obs-question-response.model';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ObservationFormErrorSheetComponent } from '../observation/observation-form-error-sheet/observation-form-error-sheet.component';
import { ObsDetailsComponent } from './obs-details/obs-details.component';
import { ObsDomainsComponent } from './obs-domains/obs-domains.component';
import { ObsSummaryComponent } from './obs-summary/obs-summary.component';
import { ObsConferenceComponent } from './obs-conference/obs-conference.component';
import { Result } from 'src/app/shared/models/result.model';

@Component({
  selector: 'app-obs-assessment',
  templateUrl: './obs-assessment.component.html',
  styleUrls: ['./obs-assessment.component.scss']
})
export class ObsAssessmentComponent {
  
  isLoading: boolean;
  internId: string;
  stateId: string;
  assessmentId: any;
  completed: boolean;
  hasObservationConfiguration: boolean = false;
  showConfigurableObservation: boolean = false;
  saveButtonClicked: boolean = false;
  finishButtonClicked: boolean = false;
  disableSaveButton: boolean = false;
  cancelBackButtonText: string = 'CANCEL';
  legendText: string = 'Observation';
  fsContact: FsContact;
  obsForm: ObsForm = new ObsForm();
  lstcert: internCertDetails;
  isTexasObs: boolean;
  lstObservations: observations[] = [];
  fieldSupervisor: FieldSupervisor;
  nextObservation: observations;
  campus: campus = new campus();
  obsLoading: boolean;
  obsAssessmentForm: FormGroup;
  responseValidationFeedback: string[] = [];
  
  @ViewChild(ObservationDetailsComponent) observationDetails: ObservationDetailsComponent;
  @ViewChild(ObsDetailsComponent) obsDetails: ObsDetailsComponent;
  @ViewChild(ObsDomainsComponent) obsDomains: ObsDomainsComponent;
  @ViewChild(ObsSummaryComponent) obsSummary: ObsSummaryComponent;
  @ViewChild(ObsConferenceComponent) obsConference: ObsConferenceComponent;

  constructor (private fb: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private observationService: ObservationService,
    private fsContactsService: FSContactsService,
    private internService: InternService,
    private globalService: GlobalService,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    if (!this.authService.authenticated) {
      this.router.navigate(['auth']);
    }

    if (this.authService.authenticated) {
      if (this.globalService.supervisor != undefined) {
        this.fieldSupervisor = this.globalService.supervisor;
      }
    }

    this.internId = this.activatedRoute.snapshot.paramMap.get('id');
    this.assessmentId = this.activatedRoute.snapshot.paramMap.get('assessId');
    this.completed = this.activatedRoute.snapshot.paramMap.get('completed') === 'true' ? true : false;
    this.stateId = this.activatedRoute.snapshot.paramMap.get('stateId');
    let hasStaticObservation = this.activatedRoute.snapshot.paramMap.get('staticObs') === 'true' ? true : false;
    this.cancelBackButtonText = this.completed ? 'BACK' : 'CANCEL';    

    if (!hasStaticObservation){
      this.getObsForm();
    }

    this.obsAssessmentForm = this.fb.group({});
  }

  onContactDateChange(updatedContactDate: string) {
    if (this.fsContact) {
      this.fsContact.contactDate = updatedContactDate;
    }
  }

  getObsForm() {
    this.isLoading = true;
    this.observationService.GetObsForm(this.stateId).subscribe({
      next:(result) => {
        if (result.success && result.returnObj) {
          this.obsForm = result.returnObj as ObsForm;
          if (this.obsForm) {
            this.hasObservationConfiguration = true;
            this.getInternsDetails();
          }
        }
        else {
          console.log(result.userMsg);
        }
        this.isLoading = false;
      },
      error: (errorObj) => {
        this.isLoading = false;
        if (errorObj.error && errorObj.error.userMsg) {
          console.log(errorObj.error.userMsg);
        }
        else {
          console.log(errorObj);
          this.snackBar.open('An error occurred while fetching observation form', 'Ok');
        }
      }
    });
  }

  onContactTypeChange(contactTypeId: Number) {
    if (this.hasObservationConfiguration && contactTypeId == ContactTypeEnum.Observation) {
      //Only load fs contact if it is not already loaded
      if (!this.fsContact) {
        this.getFsContact(this.nextObservation.AssessmentId);
      }
       else {
        this.showConfigurableObservation = true;
      }
    } else {
      this.showConfigurableObservation = false;
    }
  }

  getInternsDetails() {
    this.lstcert = new internCertDetails;
    this.internService.getInternDetailsById(this.internId, this.fieldSupervisor.FieldSupervisorId).subscribe(data => {
      this.lstcert = data;
      this.isTexasObs = this.lstcert?.Intern?.stateId == StateEnum.TX;
      this.campus = this.lstcert?.Campus;
      this.LoadObservations();
    });
    
    if (this.assessmentId) {
      this.getFsContact(this.assessmentId);
    }
  }

  LoadObservations() {
    this.lstcert.Certificates.forEach(element => {
      element.Observations.forEach(obs => {
        if (!(obs.IsCompleted || obs.IsSaved) || this.assessmentId) {
          this.lstObservations.push(obs);
        }
      });
    });

    this.nextObservation = this.lstObservations[0];
  }

  getFsContact(assessmentId: number) {              
    this.obsLoading = true;
    this.fsContactsService.GetFSContact(assessmentId).subscribe( result => {
      if (result.success && result.returnObj) {
        this.fsContact = result.returnObj as FsContact;
        if ( this.fsContact.contactDate ) {
          this.observationDetails.obsAssessment.Date = this.fsContact.contactDate;
        } else {
          this.fsContact.contactDate = this.observationDetails.obsAssessment.Date;
        }

        if (this.fsContact.contactTypeId ) {
          this.observationDetails.obsAssessment.ContactTypeId = this.fsContact.contactTypeId;
        }

        this.showConfigurableObservation = true;
        this.obsLoading = false;
      }
    });
  }

  finishAssessment() {
    this.finishButtonClicked = true;
    if (this.formalObsValidation(true)) {
      this.mapFormToFsContact();
      this.fsContactsService.FinishFsContact(this.fsContact).subscribe({
        next: (result) => {
          if (result.success) {
            if (Array.isArray(result.returnObj) && result.returnObj.length > 0) {
              const data = result.returnObj as string[];
              const emailRecipients = [
                data.slice(0, -1)
                  .join(', '),
                ...data.slice(-1)]
                .filter(x => x)
                .join(' and ');
              this.openSnackBar(`Completed Observation is queued to be emailed to ${emailRecipients}`, 'Ok', 10000)
            }
            this.router.navigate(['/intern-details', this.internId]);
          }
          else {
            this.HandleFinishAssessmentError(result);
          }
          this.finishButtonClicked = false;
        },
        error: (errorObj) => {
          if (errorObj.error && errorObj.error.userMsg) {
            this.openSnackBar(errorObj.error.userMsg, 'Ok');
          }
          else {
            this.openSnackBar('An error occurred while submitting observation form', 'Ok');
          }
          this.finishButtonClicked = false;
        }
      });
    } 
    else {
      this.finishButtonClicked = false;
    }
  }

  HandleFinishAssessmentError(result: Result) {
    switch (result.statusCode) {
      case 'VALIDATION_FAILED' : {
        if (Array.isArray(result.returnObj)){
          let errorList: string[] = result.returnObj as string[];
          this.responseValidationFeedback = [];
          this.responseValidationFeedback.push(...errorList);
          if (this.responseValidationFeedback.length > 0) {
            this.showErrorList(true);
          }
        }
        break;
      }

      case 'PDF_GENERATION_FAILED' :
      case 'EMAIL_SEND_FAILED' : {
        this.openSnackBar(result.userMsg, 'Ok', 10000);
        this.router.navigate(['/intern-details', this.internId]);
        break;
      }

      case 'ASSESSMENT_SUBMISSION_FAILED' :
      default: {
        this.openSnackBar('An error occurred while submitting observation form', 'Ok');
      }
    }
  }

  saveAssessment() {
    this.saveButtonClicked = true;
    if (this.formalObsValidation()) {
      this.mapFormToFsContact();
      this.fsContactsService.UpdateFsContact(this.fsContact).subscribe({
        next: (result) => {
          if (result.success) {
            this.openSnackBar('Observation form saved successfully', 'Ok');
            this.router.navigate(['/intern-details', this.internId]);
          }
          else {
            if (Array.isArray(result.returnObj)){
              let errorList: string[] = result.returnObj as string[];
              this.responseValidationFeedback = [];
              this.responseValidationFeedback.push(...errorList);
              if (this.responseValidationFeedback.length > 0) {
                this.showErrorList();
              }
            }
            else {
              this.openSnackBar('An error occurred while saving observation form', 'Ok');
            }
          }
          this.saveButtonClicked = false;
        },
        error: (errorObj) => {
          if (errorObj.error && errorObj.error.userMsg) {
            this.openSnackBar(errorObj.error.userMsg, 'Ok');
          }
          else {
            this.openSnackBar('An error occurred while saving observation form', 'Ok');
          }
          this.saveButtonClicked = false;
        }
      });
    } 
    else {
      this.saveButtonClicked = false
    }
  }

  formalObsValidation(finishing:boolean = false): boolean {
    this.responseValidationFeedback = [];

    this.ValidateContactDate();

    let obsDetailsErrors = this.obsDetails.validateForm();
    let obsDomainsErrors = this.obsDomains.validateForm(finishing);
    let obsSummaryErrors = this.obsSummary.validateForm(finishing);
    let obsConferenceErrors = this.obsConference.validateForm(finishing);
    
    this.responseValidationFeedback = [
      ...this.responseValidationFeedback, 
      ...obsDetailsErrors,
      ...obsDomainsErrors,
      ...obsSummaryErrors,
      ...obsConferenceErrors
    ];

    if (this.responseValidationFeedback.length > 0) {
      this.showErrorList(finishing);
      return false;
    }
    return true;
  }

  private ValidateContactDate() {
    let contactDateError = this.observationDetails.ValidateContactDate();
    if (this.observationDetails.ValidateContactDate()) {
      this.responseValidationFeedback.push(contactDateError);
    }
  }

  private showErrorList(finishing: boolean = false) {
    let method = finishing ? 'finish' : 'save';
    this.bottomSheet.open(ObservationFormErrorSheetComponent, {
      data: {
        title: `Please provide the information listed below to ${method} your observation.`,
        messages: this.responseValidationFeedback
      }
    });
  }

  mapFormToFsContact() {
    this.fsContact.contactDate = this.observationDetails.obsAssessment.Date;
    this.fsContact.contactTypeId = ContactTypeEnum.Observation;
    this.fsContact.campusId = this.fsContact.campusId ?? this.campus.CampusId;
    this.fsContact.fsId = this.fsContact.fsId ?? this.fieldSupervisor.FieldSupervisorId;
    this.fsContact.fsEmail = this.fieldSupervisor.PrimaryEmail;
    this.fsContact.isFullTimeEmployee = this.fieldSupervisor.isFullTimeEmployee;
    this.fsContact.stateId = this.stateId;
    this.fsContact.fsObservation.obsForm = this.obsForm;

    const obsDetailsValues = this.obsAssessmentForm.get('obsDetails').value;
    const obsSummaryValues = this.obsAssessmentForm.get('obsSummary').value;
    const obsConferenceValues = this.obsAssessmentForm.get('obsConference').value;
    const obsDomainsValues = this.obsAssessmentForm.get('obsDomains').value;

    this.fsContact.fsObservation = {
      ...this.fsContact.fsObservation,
      ...obsDetailsValues,
      ...obsSummaryValues,
      ...obsConferenceValues
    };

    this.mapObsDomainsValuesToResponses(obsDomainsValues);
  }

  mapObsDomainsValuesToResponses(obsDomainsValues: any) {
    this.fsContact.fsObservation.obsQuestionResponses = [];
  
    Object.entries(obsDomainsValues).forEach(([questionId, response]) => {
      const newResponse = new ObsQuestionResponse();
      newResponse.id = +questionId;
      newResponse.response = response;

      for (const domain of this.obsForm.obsDomains) {
        for (const category of domain.obsCategories) {
          if (category.obsQuestions.some(question => question.id === newResponse.id)) {
            newResponse.obsCategoryId = category.id;
            newResponse.obsCategoryDescription = category.description;
            let obsQuestion = category.obsQuestions.find(q => q.id === newResponse.id);
            newResponse.responseTypeId = obsQuestion.responseTypeId;
            newResponse.obsQuestionDescription = obsQuestion.description;
          }
        }
      }
  
      this.fsContact.fsObservation.obsQuestionResponses.push(newResponse);
    });
  }

  cancel() {
    this.router.navigate(['/intern-details', this.internId]);
  }
  
  openSnackBar(data: string, status: string, duration: number = 5000) {
    this.snackBar.open(data, status, {
      duration: duration,
    });
  }

  onRequiredDetailsInvalid(requiredDetailsInvalid: boolean) {
      this.disableSaveButton = requiredDetailsInvalid;
  }
}
